<ng-template #openSendReportModalButton let-modal>
  <!-- Select2 modal -->
  <div class="modal-header">
      <h6 class="modal-title">Send Service Report</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <!-- <h6>Service Report </h6> -->
      <!-- Select2 -->

      <div class="form-group row">

          <!-- <div class="col-lg-12 mt-4">
              <label> Event Summary</label>
              <p>

              </p>
          </div> -->

          <div class="col-lg-12 mt-4">
              <label> Email Message </label>

              <app-textarea
                  [class]="'form-control'"
                  [id]="'name'"
                  [value]="mail.message"
                  [placeholder]="'Enter Email Message'"
                  [(inputModel)]="mail.message">
              </app-textarea>
          </div>

        </div>

  </div>
  <div class="modal-footer">
      <button (click)="sendReport($event)" class="btn ripple btn-primary" type="button">Send Report</button>
      <button type="button" class="btn ripple btn-secondary" (click)="modal.close('Close click')">Close</button>
  </div>

</ng-template>

<button id="openSendReportModalButton" [hidden]="true" data-toggle="modal" (click)="openPopUPModal(openSendReportModalButton)" data-target="#myModal">Open Modal</button>

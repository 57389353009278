import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../shared/services/auth.service';
import { first } from 'rxjs/operators';
import { CookieService } from '../shared/services/cookie.service';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {

  public loginForm: FormGroup;
  public submitted: boolean = false;
  public isloading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authentication: AuthenticationService,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {

    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    })
  }

  get loginFormControl(){
    return this.loginForm.controls;
  }

  public isLoginFailed:boolean = false;

  Submit(){

    let $this = this

    let username = this.loginForm.controls['username'].value
    let password = this.loginForm.controls['password'].value
    this.isloading = true;

    this.authentication.login(username, password)
    .pipe(first())
      .subscribe(
        data => {

            if(data.access_token){

                $this.cookieService.setCookie('user_pass', JSON.stringify(data), 1);
                $this.router.navigate(['/dashboard']);
            }else{

              $this.isLoginFailed = true;
            }

            $this.isloading = false;

        },
        error => {

          $this.isloading = false;
          $this.isLoginFailed = true;
          // console.log("data for login: ", error)
        });

  }
}

import { PathService } from './../path.service';
import { ServiceRecord, Hospital, Equipment, Vehicle, JobLocation } from './../../../components/reporting/service-report/editor/model/report.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { RequestService } from './../request.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class ReportAccessor {

  constructor(private appService: AppService, private pathService: PathService){}

   // Private
   private _patient: BehaviorSubject<ServiceRecord[] | null> = new BehaviorSubject(null);
   private _hospital: BehaviorSubject<Hospital[] | null> = new BehaviorSubject(null);
   private _equipment: BehaviorSubject<Equipment[] | null> = new BehaviorSubject(null);
   private _vehicle: BehaviorSubject<Vehicle[] | null> = new BehaviorSubject(null);
   private _locations: BehaviorSubject<JobLocation[] | null> = new BehaviorSubject(null);

   /** * Getter for tags             */
   get patients$(): Observable<ServiceRecord[]>
   {
       return this._patient.asObservable();
   }
   get hospitals$(): Observable<Hospital[]>
   {
       return this._hospital.asObservable();
   }
   get equipments$(): Observable<Equipment[]>
   {
       return this._equipment.asObservable();
   }
   get vehicles$(): Observable<Vehicle[]>
   {
       return this._vehicle.asObservable();
   }
   get locations$(): Observable<JobLocation[]>
   {
       return this._locations.asObservable();
   }

   getPatient(options){
      this.appService.get(this.pathService.SERVICE_LIST_RECORD_ITEMS ).subscribe(response => {
          if(response.status=="success"){ this._patient.next(response.data) }
      }, error => "")
   }

    getHospital(){
      this.appService.get(this.pathService.SERVICE_LIST_HOSPITALS).subscribe(response => {
          if(response.status=="success"){ this._hospital.next(response.data) }
      }, error => "")
   }

   getEquipment(){
      this.appService.get(this.pathService.SERVICE_LIST_EQUIPMENTS).subscribe(response => {
          if(response.status=="success"){ this._equipment.next(response.data) }
      }, error => "")
    }

  getVehicle(){
      this.appService.get(this.pathService.LIST_VEHICLE_PATH).subscribe(response => {
          if(response.status=="success"){ this._vehicle.next(response.data) }
      }, error => "")
    }

  getLocation(){
      this.appService.get(this.pathService.LIST_LOCATION_PATH).subscribe(response => {
          if(response.status=="success"){ this._locations.next(response.data) }
      }, error => "")
    }

}

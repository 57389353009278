import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment.prod';

import { CookieService } from '../services/cookie.service';
import { User } from './../models/auth.models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    user: User;

    access: Access;

    constructor(private http: HttpClient, private cookieService: CookieService) {

        this.access = this.setAccess();
    }

    public USER_ACCESS          = "user";
    public CHAT_ACCESS          = "chat";
    public JOB_ACCESS           = "job";
    public MESSAGE_ACCESS       = "message";
    public REPORT_ACCESS        = "report";
    public SETTINGS_ACCESS      = "settings";
    public WEBSITE_ACCESS       = "website";
    public CALENDAR_ACCESS       = "calendar";
    public ACTIVITY_ACCESS       = "activity";
    public ASSIGNED_ACCESS       = "assigned";
    public ELT_LOGS_ACCESS       = "eltlogs";
    public ORGANIZTION_ACCESS       = "organization";


    check(key: string){

        let user:any = this.currentUser();
        return this.control(user, key)
    }


    control(user: any, key: string){

        try{

            let role = user.roles[key];

            if(role){
                return role;
            }
            return new Control();

        }catch(e){
            return {
                read: false,
                write: false,
                execute: false
            }
        }
    }

    parseAccess(user: any){

        let access: Access = new Access();

        let param:any = {}
        param.user = {}
        param.user.roles = user

        // console.log("Result Parser: ", param, user)

        access.activity = this.control(param, this.ACTIVITY_ACCESS)
        access.assigned = this.control(param, this.ASSIGNED_ACCESS)
        access.calendar = this.control(param, this.CALENDAR_ACCESS)
        access.chat = this.control(param, this.CHAT_ACCESS)
        access.job = this.control(param, this.JOB_ACCESS)
        access.message = this.control(param, this.MESSAGE_ACCESS)
        access.report = this.control(param, this.REPORT_ACCESS)
        access.settings = this.control(param, this.SETTINGS_ACCESS)
        access.user = this.control(param, this.USER_ACCESS)
        access.website = this.control(param, this.WEBSITE_ACCESS)
        access.eltlogs = this.control(param, this.ELT_LOGS_ACCESS)
        access.organization = this.control(param, this.ORGANIZTION_ACCESS)
        // // console.log("Priviledges", user, access)

        return access;
    }

     // ACCESS_CONTROLLED = this.MENUITEMS;
  setAccess()
  {

    let access: Access = new Access();

    access.activity = this.check(this.ACTIVITY_ACCESS)
    access.assigned = this.check(this.ASSIGNED_ACCESS)
    access.calendar = this.check(this.CALENDAR_ACCESS)
    access.chat = this.check(this.CHAT_ACCESS)
    access.job = this.check(this.JOB_ACCESS)
    access.message = this.check(this.MESSAGE_ACCESS)
    access.report = this.check(this.REPORT_ACCESS)
    access.settings = this.check(this.SETTINGS_ACCESS)
    access.user = this.check(this.USER_ACCESS)
    access.website = this.check(this.WEBSITE_ACCESS)
    access.eltlogs = this.check(this.ELT_LOGS_ACCESS)
    access.organization = this.check(this.ORGANIZTION_ACCESS)



    return access;
  }

    /**
     * Returns the current user
     */
    public currentUser(): User {

        // if (!this.user) {
        //     this.user = JSON.parse(this.cookieService.getCookie('currentUser'));
        // }

        let user:any = JSON.parse(this.cookieService.getCookie('currentUser'));

        if(!user){
            return null;
        }

        this.user = user.user;

        return this.user;
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/api/auth/user/admin/login`, { email, password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.access_token) {
                    this.user = user;

                    // console.log("User -> ", this.user)

                    // store user details and jwt in cookie
                    this.cookieService.setCookie('currentUser', JSON.stringify(user), 1);
                }

                return user;
            }));
    }

    token(){

        let response = this.cookieService.getCookie("user_pass")
        if(! response){
            return null;
        }
        let access = JSON.parse(response);
        if(! access){
            return null;
        }
        return access.access_token
    }



    identifier(){

        try{
            let response = this.cookieService.getCookie("user_pass")
            if(! response){
                return false;
            }
            let access = JSON.parse(response);
            if(! access){
                return false;
            }
            return access.user.identifier
        }catch(e){
            return false;
        }
    }

    imageExists(url, callback) {

        var img = new Image();
        img.onload = function() { callback(true); };
        img.onerror = function() { callback(false); };
        img.src = url;
      }

    /**
     * Logout the user
     */
    logout() {
        // remove user from local storage to log user out
        this.cookieService.deleteCookie('currentUser');
        this.user = null;
        this.cookieService.setCookie('currentUser', "", -1);
        this.cookieService.setCookie("user_pass", "", -1)
    }

    isLoggedIn(){

        return !!this.token();
    }

    isAdmin(){
        let user = this.currentUser();
        if(!user){
            return false;
        }

        // // console.log("Is admin status:: ", user.is_admin, user)

       return !!user.is_admin;
    }

    userid(){
        let user = this.currentUser();
        if(!user){
            return null;
        }
       return user.id;
    }

    isClient(){
        let user = this.currentUser();
        if(!user){
            return false;
        }
       return user.client_id !== 0;
    }
}

export class Access {

    public activity: Control = new Control
    public assigned: Control = new Control
    public calendar: Control = new Control
    public chat: Control = new Control
    public job: Control = new Control
    public message: Control = new Control
    public report: Control = new Control
    public settings: Control = new Control
    public user: Control = new Control
    public website: Control = new Control
    public organization: Control = new Control
    public eltlogs: Control = new Control
}

export class Control {
    public read: boolean = false
    public write: boolean = false
    public execute: boolean = false
}

import { UserAssigned } from './../../../components/jobs/details/details.model';
import { ToastrService } from 'ngx-toastr';
import { Assigned, Job, Payable, PayableHour } from '../../models/job.model';
import { PathService } from '../path.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class JobAccessor {

  constructor(
    private appService: AppService,
    private pathService: PathService,
    private toastr: ToastrService){}

   // Private
   private _jobs: BehaviorSubject<Job[] | null> = new BehaviorSubject(null);
   private _assigned: BehaviorSubject<UserAssigned[] | null> = new BehaviorSubject(null);

   /** * Getter for tags             */
   get jobs$(): Observable<Job[]>
   {
       return this._jobs.asObservable();
   }

   get assigned$(): Observable<UserAssigned[]>
   {
       return this._assigned.asObservable();
   }

   getJobs(){

      this.appService.post(this.pathService.JOBS_LIST, {type: 1}).subscribe(response => {

        console.log("Testing:::", response)
          if(response.status=="success"){
            this._jobs.next(response.data)
            return
          }
          this.toastr.error("Unablet to find any record for your request")

      }, error => this.toastr.error("Unablet to find any record for your request"))
   }

   getAssignedList(body: any = {}){

    console.log("getAssignedList:::", )

    this.appService.post(this.pathService.FIND_ASSIGNED_JOB_USER, body).subscribe(response => {

      console.log("getAssignedList:::", response)
        if(response.status=="success"){
          this._assigned.next(response.data)
          return
        }
        this.toastr.error("Unablet to find any record for your request")

    }, error => this.toastr.error("Unablet to find any record for your request"))

   }


   updateReport(option){
      return this.appService.post(this.pathService.SERVICE_UPDATE_RECORD, option)
   }

   sendReport(option){
      return this.appService.post(this.pathService.SEND_REPORTING_SERVICE, option)
   }
}

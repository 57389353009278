import { UpdatePatientComponent } from './modals/update-patient/update-patient.component';
import { SendReportComponent } from './modals/send-report/send-report.component';
import { AddPatientComponent } from './modals/add-patient/add-patient.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { QuillModule } from 'ngx-quill';
import { AccessNoticeComponent } from './access/access-notice.component';
import { AlertComponent } from './alerts/alert.component';
import { InlineChatComponent } from './chat/inline-chat.component';
import { ParticipantsComponent } from './chat/invite/chat-invite.component';
import { ImagePickerComponent } from './element/picker/image-picker.component';
import { TextEditorComponent } from './element/text-editor/text-editor.component';
import { TextInputComponent } from './element/text/text-input.component';
import { IDeleteComponent } from './idelete/idelete.component';
import { IEditorComponent } from './ieditor/ieditor.component';
import { InputComponent } from './input/input.component';
import { AddReportComponent } from './modals/add-report/add-report.component';
import { TextAreaComponent } from './textarea/textarea.component';
import { UploaderComponent } from './uploader/uploader.component';
import { IDateEditorComponent } from './ieditor/idate-editor/idate-editor.component';
import { IDateNormalEditorComponent } from './ieditor/idate-normal/idate-normal.component';
import { MiniProfileComponent } from './mini-profile/mini-profile.component';
import { CopyChannelComponent } from './chat/log/copy-chat.component';
import { AssignSeniorStaffComponent } from './modals/assign-senior-staff/assign-senior-staff.component';

@NgModule({
   imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    DropzoneModule,
    QuillModule.forRoot(),
    NgbModule
   ],
    declarations: [
        TextAreaComponent,
        InputComponent,
        AlertComponent,
        TextInputComponent,
        TextEditorComponent,
        ImagePickerComponent,
        AccessNoticeComponent,
        IEditorComponent,
        IDeleteComponent,
        InlineChatComponent,
        ParticipantsComponent,
        AddReportComponent,
        UploaderComponent,
        IDateEditorComponent,
        MiniProfileComponent,
        CopyChannelComponent,
        AddPatientComponent,
        IDateNormalEditorComponent,
        AssignSeniorStaffComponent,
        SendReportComponent,
        UpdatePatientComponent
    ],
    exports: [
        TextAreaComponent,
        InputComponent,
        AlertComponent,
        TextInputComponent,
        TextEditorComponent,
        ImagePickerComponent,
        AccessNoticeComponent,
        IEditorComponent,
        IDeleteComponent,
        InlineChatComponent,
        ParticipantsComponent,
        AddReportComponent,
        UploaderComponent,
        IDateEditorComponent,
        MiniProfileComponent,
        AddPatientComponent,
        IDateNormalEditorComponent,
        AssignSeniorStaffComponent,
        SendReportComponent,
        UpdatePatientComponent
    ]
})

export class ComponentModule { }

<!-- <input
                    [type]="type" 
                    [class]="class" 
                    autocomplete="off"
                    [(ngModel)]="inputModel"
                    (ngModelChange)="inputModelChange.emit(inputModel)"
                    [id]="id"
                    [placeholder]="placeholder"
                    [value]="value">
                    <small>{{ info }}</small> -->

<div class="ieditor" (blur)="cleanInput()" ngDefaultControl [(ngModel)]="label" (blur)="validate(content)" contenteditable="true" #content>
    {{ label }}
</div>


<ng-template #addRecord let-modal>
  <!-- Select2 modal -->
  <div class="modal-header">
      <h6 class="modal-title">Service Items</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <!-- Select2 -->

      <div class="form-group row">

          <div class="col-lg-12 mt-4 text-center">

              <label class="custom-switch">
                  <input type="checkbox" checked (click)="onSwitchToggled('patient_name')" name="custom-switch-checkbox" class="custom-switch-input">
                  <span class="custom-switch-indicator"></span>
                  <span class="custom-switch-description" style="max-width: 350px ;">Click the button to disable patient name if you don't know the name of the name and initials of this patient</span>
              </label>
          </div>

          <div class="col-lg-12">

            <div class="row">

                <div class="col-lg-4 mt-4">

                    <label>Gender</label>
                    <ng-select class="form-control p-0" [(ngModel)]="new_record.gender">
                        <ng-option *ngFor="let data of genders; let i = index" value="{{ data.name }}">{{ data.name }}</ng-option>
                    </ng-select>

                </div>

                <div class="col-lg-8 mt-4" *ngIf="is_patient_name">
                    <label>
                        Patient Name
                    </label>
                    <app-input
                        [type]="'text'"
                        [class]="'form-control'"
                        [id]="'patient'"
                        [value]="new_record.patient"
                        [placeholder]="'Staff Name'"
                        [(inputModel)]="new_record.patient">
                    </app-input>

                </div>

            </div>

          </div>

          <!-- <div class="col-lg-6 mt-4">

              <label>Staff</label>
              <ng-select class="form-control p-0" (change)="onDataSelected($event, 'user')">
                  <ng-option *ngFor="let data of users; let i = index" value="{{ data.id }}">{{ data.name }}</ng-option>
              </ng-select>

          </div> -->


          <div class="col-lg-6 mt-4">

            <div class="row">

                <div class="col-lg-12 mt-4">
                  <label>Hospital</label>
                  <ng-select class="form-control p-0" [(ngModel)]="new_record.hospital_id">
                      <ng-option value="0">Not Hospitalized</ng-option>
                      <ng-option *ngFor="let data of hospitals; let i = index" value="{{ data.id }}">{{ data.name }}</ng-option>
                  </ng-select>
              </div>

              <div class="col-lg-12 mt-4">
                  <label>Location</label>
                  <ng-select class="form-control p-0" [(ngModel)]="new_record.location_id">
                      <ng-option value="0">Select Location</ng-option>
                      <ng-option *ngFor="let data of locations" value="{{ data.id }}">{{ data.name }}</ng-option>
                  </ng-select>
              </div>

              <div class="col-lg-12 mt-4">
                <label>Time In</label>
                <app-input
                    [type]="'datetime-local'"
                    [class]="'form-control'"
                    [id]="'time_in'"
                    [value]="new_record.home"
                    [placeholder]="'Select Time'"
                    [(inputModel)]="new_record.time_in">
                </app-input>
            </div>

            <div class="col-lg-12 mt-4">

                <label>Do you need an Ambulance?</label>
                <ng-select class="form-control p-0" [(ngModel)]="new_record.is_ambulance_needed">
                    <ng-option value="1">Yes</ng-option>
                    <ng-option value="0">No</ng-option>
                </ng-select>

            </div>

            <div class="col-lg-12 mt-4">

                <label>Select Ambulance, if Required</label>
                <ng-select class="form-control p-0" [(ngModel)]="new_record.vehicle_id">
                    <ng-option value="0">Not Required</ng-option>
                    <ng-option *ngFor="let data of vehicles" value="{{ data.id}}">{{ data.name }}</ng-option>
                </ng-select>

            </div>




              <!-- End Row -->
            </div>

          </div>
          <div class="col-lg-6 mt-4">

            <div class="row">

                <div class="col-lg-12 mt-4">
                    <label>Equipment</label>

                      <div style="max-height:400px; overflow-y:scroll;width:100%">

                        <table class="table table-invoice table-bordered">

                            <tbody>
                                <tr *ngFor="let data of equipments; let i = index" value="{{ data.id }}">
                                    <td>{{ data.name }}</td>
                                    <td>£0</td>
                                    <td class="tx-4">
                                        <input type="checkbox" (change)="onCheckedEquipment($event)" name="" value="{{ data.id }}" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                      </div>

                  </div>

              </div>

          </div>



          <div class="col-lg-12 mt-4">
              <label>Treatment Descriptions</label>
              <app-input
                  [type]="'text'"
                  [class]="'form-control'"
                  [id]="'illness_or_treatment_description'"
                  [value]="new_record.illness_or_treatment_description"
                  [placeholder]="'Enter Description'"
                  [(inputModel)]="new_record.illness_or_treatment_description">
              </app-input>
          </div>

          <div class="col-lg-12">

              <div class="row">

                  <div class="col-lg-4 mt-4">
                      <label class="custom-switch">
                          <input type="checkbox" (click)="onSwitchToggled('exhibitor')" name="custom-switch-checkbox" class="custom-switch-input">
                          <span class="custom-switch-indicator"></span>
                          <span class="custom-switch-description">Exhibitor</span>
                      </label>
                  </div>
                  <div class="col-lg-4 mt-4">
                      <label class="custom-switch">
                          <input type="checkbox" (click)="onSwitchToggled('contractor')" name="custom-switch-checkbox" class="custom-switch-input">
                          <span class="custom-switch-indicator"></span>
                          <span class="custom-switch-description">Contractor</span>
                      </label>
                  </div>

                  <div class="col-lg-4 mt-4">
                      <label class="custom-switch">
                          <input type="checkbox" (click)="onSwitchToggled('staff')" name="custom-switch-checkbox" class="custom-switch-input">
                          <span class="custom-switch-indicator"></span>
                          <span class="custom-switch-description">Arrena Staff</span>
                      </label>
                  </div>

                  <div class="col-lg-4 mt-4">
                      <label class="custom-switch">
                          <input type="checkbox" (click)="onSwitchToggled('visitor')" name="custom-switch-checkbox" class="custom-switch-input">
                          <span class="custom-switch-indicator"></span>
                          <span class="custom-switch-description">Visitor</span>
                      </label>
                  </div>

                  <div class="col-lg-4 mt-4">
                      <label class="custom-switch">
                          <input type="checkbox" (click)="onSwitchToggled('catering')" name="custom-switch-checkbox" class="custom-switch-input">
                          <span class="custom-switch-indicator"></span>
                          <span class="custom-switch-description">Catering</span>
                      </label>
                  </div>



              </div>
          </div>



        </div>

  </div>
  <div class="modal-footer">
      <button (click)="onCreateRecord()" class="btn ripple btn-primary" type="button">Add Patient Record</button>
      <button type="button" class="btn ripple btn-secondary" (click)="modal.close('Close click')">Close</button>
  </div>

</ng-template>

<button id="openModalButton" class="btn btn-info btn-sm mr-2" data-toggle="modal" (click)="openAddRecord(addRecord)" data-target="#myModal">Add Patient Record</button>

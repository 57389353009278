<ng-template #documentUploader let-modal>
    <!-- Select2 modal -->
    <div class="modal-header">
        <h6 class="modal-title">Uploader</h6>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        
        <div class="form-group row">

            <div class="col-lg-12">
                <div class="dropzone dropzone-primary text-center" [dropzone]="config1">
                    <div class="dz-message needsclick">
                        <i class="fe fe-upload-cloud" aria-hidden="true"></i>
                        <h6 class="text-center">Drop files here or click to upload.</h6>
                    </div>
                </div>
            </div>

        </div>

        <small class="mt-3" *ngIf="!is_update">
            Only .jpeg, .png, .jpg are allowed
        </small>

    </div>
</ng-template>

<button (click)="showUPloader(documentUploader)" type="button" class="btn btn-sm btn-primary pull-right">
    <i class="fe fe-plus mr-2"></i> Upload Lisence or Certificates
</button>
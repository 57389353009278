import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { AddReportForm } from 'src/app/components/reporting/service-report/report.model';
import { UserList } from 'src/app/shared/models/auth.models';
import { Job } from 'src/app/shared/models/job.model';
import { DateService } from 'src/app/shared/services/date.service';
import { JobReportService } from 'src/app/shared/services/jobs/job.report.service';
import { JobService } from 'src/app/shared/services/jobs/job.service';
import { UserService } from 'src/app/shared/services/users/user.service';

@Component({
  selector: 'add-report',
  templateUrl: './add-report.component.html',
  styleUrls: ['./add-report.component.scss']
})

/*
 *
 */
export class AddReportComponent implements OnInit, AfterViewInit {

  public modalValue: Subject<any> = new Subject();

  @Input() jobid: number;

  public form: AddReportForm = new AddReportForm();

  public jobs: Array<Job> = new Array<Job>();

  public users: Array<UserList> = new Array<UserList>()

  public levels: any = [
    {
      id:0,
      name: "None"
    },
    {
      id:1,
      name: "Low"
    },
    {
      id:2,
      name: "Medium"
    },
    {
      id:3,
      name: "High"
    }
]

    constructor(
      private modal: NgbModal,
      private jobService: JobService,
      private reportService: JobReportService,
      private userService: UserService){

    }

    ngAfterViewInit(){

    }

    ngOnInit(): void {

      this.loadJobs();

      this.loadUsers();

    }

    open(){

        // console.log("Add Report")

        let doc:any = document.querySelector("#openModalButton")
        doc.click();
    }

    openPopUPModal(addReportModal){

        this.modal.open(addReportModal, {size: 'md'})
    }

    loadJobs(){

      let $this = this

      this.jobService.all(function(result, status){

        if(status){
            $this.jobs = result
        }

      }, {type: "jobs"})
    }

    loadUsers(){

      let $this = this

      this.userService.all(function(result, status){

        // console.log(result, status)

         if(status){
            $this.users = result
         }

      })
  }

    onSubmit(){

    // console.log(this.form);

    let $this = this

    if(this.jobid != undefined){
        this.form.job_id = this.jobid
    }

    let options = this.form

    this.reportService.send(options, function(result, status){

      if(status){

        $this.modal.dismissAll()
        $this.modalValue.next({status:"success", message: result.message})

        document.location.href = document.location.href
        return;
      }

      $this.modalValue.next({status:"error", message: result.message})

    })
  }

  onSelected($event: any, target: any){

    switch(target){
       case "user": this.form.senior_staff_id = $event; break;
       case "o2": this.form.use_of_o2 = $event; break;
       case "job": this.form.job_id = $event; break;
       case "entonox": this.form.use_of_entonox = $event; break;
    }

  }

}

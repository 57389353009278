import { ReportAccessor } from './../../../../shared/services/jobs/report.accessor';
import { ToastrService } from 'ngx-toastr';
import { RequestService } from 'src/app/shared/services/request.service';
import { PathService } from 'src/app/shared/services/path.service';
import { ServiceRecord, JobLocation, Vehicle, Hospital, Equipment } from './../../../reporting/service-report/editor/model/report.model';
import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit, AfterViewInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.scss']
})

/*
 *
 */
export class AddPatientComponent implements OnInit {

  public modalValue: Subject<any> = new Subject();
  public changingValue: Subject<any> = new Subject();

  @Input() reportid: number;
  @Output() onSuccessResponse = new EventEmitter<any>();

 constructor(
  private model: NgbModal,
  private pathService: PathService,
  private requestService: RequestService,
  private reportAccessor: ReportAccessor,
  private toastr: ToastrService){}

  public genders: any = [
    {id: 1, name: "Male"},
    {id: 2, name: "Female"  },
    {id: 3, name: "Rather Not Say"}
  ]

  ngOnInit(): void {

    this.reportAccessor.equipments$.subscribe(response => {
        this.equipments = response;
    })

    this.reportAccessor.hospitals$.subscribe(response => {
        this.hospitals = response;
    })

    this.reportAccessor.locations$.subscribe(response => {
        this.locations = response;
    })

    this.reportAccessor.vehicles$.subscribe(response => {
        this.vehicles = response;
    })

    this.reportAccessor.getEquipment();
    this.reportAccessor.getHospital();
    this.reportAccessor.getLocation();
    this.reportAccessor.getVehicle();

  }

 /**
   * Use this to determine if the patient name is known or not
   */
  public is_patient_name: boolean = true;

  public locations: Array<JobLocation> = new Array<JobLocation>()
  public vehicles: Array<Vehicle> = new Array<Vehicle>()
  public hospitals: Array<Hospital> = new Array<Hospital>()
  public equipments: Array<Equipment> = new Array<Equipment>()

  public new_record: ServiceRecord = new ServiceRecord;

  onSwitchToggled(type){

    switch(type){
      case 'visitor': this.new_record.is_visitor = this.new_record.is_visitor ? 0: 1; break;
      case 'staff': this.new_record.is_arena_staff = this.new_record.is_arena_staff ? 0: 1;break;
      case 'catering': this.new_record.is_catering = this.new_record.is_catering ? 0: 1; break;
      case 'contractor': this.new_record.is_contractor = this.new_record.is_contractor ? 0: 1; break;
      case 'exhibitor': this.new_record.is_exhibitor = this.new_record.is_exhibitor ? 0: 1; break;
      case 'patient_name': this.is_patient_name = this.is_patient_name ? false : true; break
    }

    // console.log("Testing cahnges;;;;", type, this.new_record)
  }

  openAddRecord(addModal:any ){
    this.model.open(addModal, {size: "lg"})
  }

  onChecked($event, type, old_value:any){

    this.new_record.is_arena_staff =  this.new_record.is_catering =
    this.new_record.is_contractor =  this.new_record.is_exhibitor =
    this.new_record.is_visitor = 0

    switch(type){
        case 'visitor': this.new_record.is_visitor = old_value ? 0: 1; break;
        case 'staff': this.new_record.is_arena_staff = old_value ? 0: 1;break;
        case 'catering': this.new_record.is_catering = old_value ? 0: 1; break;
        case 'contractor': this.new_record.is_contractor = old_value ? 0: 1; break;
        case 'exhibitor': this.new_record.is_exhibitor = old_value ? 0: 1; break;
    }

    // console.log("Testing cahnges;;;;", type, this.new_record)
  }

  public message: string = "Are you sure you want to do this?"
  public selected

  onCreateRecord(){

    let $this = this

    let data:any = this.new_record;
    delete data["hospital"]
    delete data["equipment"]
    delete data["location"]
    delete data["vehicle"]
    delete data["user"]

    this.new_record.equipment = this.selectedEquipment;

    this.new_record.report_id = this.reportid

    this.requestService._post(this.pathService.CREATE_RECORDS, this.new_record, function(result, status){

      if(status){
          $this.onSuccessResponse.emit(true)
          $this.toastr.success("Patient successfully added to this report")
          $this.model.dismissAll()
       }
    })
  }

  private selectedEquipment  = []
  onCheckedEquipment(event) {

    // set child to true if checked
    if(event.target.checked){

        this.selectedEquipment.push(event.target.value)
    } else {
      //set child model to false
      this.selectedEquipment = this.selectedEquipment.filter(function(item){
        return item !== event.target.value;
      });

    }
  }

}

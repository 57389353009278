import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PathService {

  constructor() { }

  public CHECK_TOKEN_ACCESS       = `auth/check/access`;
  public SERVICE_SEARCH_RECORD    = `report/search/reports`
  public SERVICE_UPDATE_RECORD    = `report/update/reports`

  public CREATE_EQUIPMENT_RECORD  = `report/create/equipment`;
  public CREATE_HOSPITAL_RECORD   = `report/create/hospital`;
  public CREATE_RECORDS           = `report/create/items`;
  public UPDATE_RECORDS           = `report/update/items`;

  public SERVICE_LIST_RECORD_ITEMS  = 'report/search/items'
  public SERVICE_LIST_EQUIPMENTS    = 'report/fetch/equipment'
  public SERVICE_DELETE_EQUIPMENTS  = 'report/delete/equipment'

  public CREATE_DRUG_ITEMS          = 'accessory/create/drug'
  public LIST_DRUG_ITEMS            = 'accessory/search/drug'
  public DELETE_DRUGS_PATH          = 'accessory/delete/drug'

  public CREATE_OBSERVATION_ITEMS   = 'accessory/create/observation'
  public LIST_OBSERVATION_ITEMS     = 'accessory/search/observation'
  public DELETE_OBSERVATION_PATH    = 'accessory/delete/observation'

  public SERVICE_LIST_HOSPITALS     = 'report/fetch/hospital'

  public LIST_ALL_USERS             = 'user/list';
  public SEND_REPORTING_SERVICE     = 'report/send/project/service/record';
  public LIST_ALL_ASSIGNED          = `fetch/job/assigned/payable/list/all`;
  public LIST_ALL_ASSIGNED_SEARCH   = `fetch/job/assigned/payable/find/all`;

  public LIST_PRIVILEGES            = 'auth/admin/privileges';

  public DELETE_USER_PATH = `auth/admin/delete/`
  public DELETE_ASSIGNED_PATH = `delete/job/assigned/`
  public DELETE_ASSIGN_USER_PATH = 'delete/job/assigned/'

  public DELETE_AVAILABILITY_PATH = `delete/availability/`
  public DELETE_REPORT_PATH = 'report/delete/reports/'

  /**
   * VEHICLES & LOCATION
   */
  public CREATE_LOCATION_PATH = `locations/create/location`
  public DELETE_LOCATION_PATH = `locations/delete/location/`
  public LIST_LOCATION_PATH = `locations/list/locations`

  public CREATE_VEHICLE_PATH = 'vehicles/create/vehicle'
  public DELETE_VEHICLE_PATH = 'vehicles/delete/vehicle/'
  public LIST_VEHICLE_PATH = 'vehicles/list/vehicles'

  /** USERS  */

  public JOBS_LIST = `fetch/job/list`;
  public FIND_ASSIGNED_JOB_USER =  `fetch/job/assigned/list`;

}

import { JobReportService } from './../../../../shared/services/jobs/job.report.service';
import { ToastrService } from 'ngx-toastr';
import { JobAccessor } from '../../../../shared/services/jobs/job.accessor';
import { Component, HostListener, OnInit, AfterViewInit, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'send-report',
  templateUrl: './send-report.component.html',
  styleUrls: ['./send-report.component.scss']
})

/*
 *
 */
export class SendReportComponent implements OnInit, AfterViewInit {

  @Output() onSuccessResponse: EventEmitter<any> = new EventEmitter();
  @Input() eventid: number;

    constructor(
      private modal: NgbModal,
      private reportService: JobReportService,
      private jobAccessor: JobAccessor,
      private toastr: ToastrService)
      {

    }

    public mail: Mail = new Mail()

    ngAfterViewInit(){

    }

    ngOnInit(): void {

      this.mail.job_id = this.eventid
      this.mail.message = "Hi All, attached information contain the detailed report for this event. Please review it and let me know if I need to correct it."
    }

    open(){
        let doc:any = document.querySelector("#openSendReportModalButton")
        doc.click();
    }

    openPopUPModal(addReportModal){

        this.modal.open(addReportModal, {size: 'md'})
    }

    sendReport($event){

    if( !this.mail.job_id){ return; }

    const body:any = this.mail

    this.jobAccessor.sendReport(body).subscribe(response => {

        if(response.status == "success"){
            this.onSuccessResponse.emit(true);
            this.toastr.success("Job/Event report successfully sent")
            this.modal.dismissAll()
            return;
        }
        this.toastr.error("Unable to send Job/Event Report")
    })
  }
}

class Mail {
    job_id: number;
    message: string
}

import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Subject } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services/auth.service';
import { RequestService } from 'src/app/shared/services/request.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'uploader',
  templateUrl: `./uploader.component.html`,
  styleUrls: ['./uploader.component.scss'],
})
export class UploaderComponent {

    @Input() state: boolean = true
    @Input() label: string = ""

    @Input() id: string = ""
    @Input() column: string = ""
    @Input() table: string = ""

    @Input() inputModel: string;
    @Output() inputModelChange = new EventEmitter<string>();

    public is_required: boolean = false;

    constructor(
      private requestService: RequestService,
      private modalService: NgbModal,
      private authService: AuthenticationService){

    }


    public fileType: string = "Certificates"; //license
    ngOnInit(): void {

      this.config1.url = `${environment.apiUrl}/api/user/profile/upload`
    }

    public param = "";
    public initial: any = ""

    public config1: DropzoneConfigInterface = {
      clickable: true,
      maxFiles: 20,
      addRemoveLinks: true,
      autoReset: null,
      errorReset: null,
      cancelReset: null,
      paramName: "image",
      withCredentials: false,
      method: "POST",
      headers: {
        'Cache-Control': null,
        'X-Requested-With': null,
        'Authorization':`Bearer ${this.authService.token()}`
     } ,
     params: {
        user_id: this.authService.userid(),
        type: this.fileType,
        source: 'web'
      }
    };

    validate($event){

      this.param = $event.innerText;
      this.initial = this.label;

      if(this.param == ""){
          return;
      }

      // if(!this.id || !this.column || !this.param || !this.table ){
      //     this.label = this.initial;
      //     return
      // }



      const body:any = {}
      body.data = {}

      body.target         = {id: this.id}
      body.data.key       = this.column
      body.data.value     = this.param
      body.table          = this.table

      let $this = this;
      // table, column, value to add, target
      this.requestService._post("table/data/update", body, function(result, status){

          if(status){
              $this.label = $this.param;
              return;
          }

          // console.log("Response from server for data table update:: ", result)

          $this.label = $this.initial;
      })



      // console.log("Content Editable: ------------------- >>>> ", this.label,  $event.innerText.replace(/(\r\n|\n|\r)/gm, ""))
    }

    showUPloader(documentUploader: any){

        this.modalService.open(documentUploader)
    }

}

import { UserRecord } from 'src/app/shared/models/auth.models';

export class ServiceRecord
{
    public id: number
    public patient: string
    public report_id: number
    public time_in: string
    public user_id: number
    public is_arena_staff: number	= 0;
    public is_catering: number      = 0;
    public is_contractor: number    = 0;
    public is_exhibitor: number	    = 0;
    public is_visitor: number	    = 0;
    public illness_or_treatment_description: string
    public hospital_id: number
    public vehicle_id: number
    public location_id: number
    public gender: string
    public status: number
    public created_at: string
    public updated_at: string
    public hospital: Hospital = new Hospital
    public equipment: AttachedEquipment[] = Array<AttachedEquipment>()
    public user: UserRecord = new UserRecord
    public location: JobLocation = new JobLocation
    public vehicle: Vehicle =  new Vehicle
    public is_ambulance_needed: number
}

export class Service {
    job_id: number
    senior_staff_id: number
    date: string
    paramedics: number
    technicians: number
    clock_in_time: string
    clock_out_time: string
    callouts:number
    ear_pugs: number
    ambulances: number
    arena_staff: number
    catering: number
    event_staff: number
    competitor: number
    visitor: number
    use_of_o2: string
    use_of_entonox: string
}

export class Hospital {

    public id: number;
    public name: string;
    public address: string
    public phone: string
    public status: number;
    public created_at: string
    public updated_at: string
}

export class Equipment {

  public id: number;
  public name: string;
  public type: string
  public status: number;
  public created_at: string;
  public updated_at: string;
}

export class EmailReport {
  public report_id: number
  public email: string
  public message: string
}

export class JobLocation
{
    id!: number;
    name!: string;
    address!: string;
    postcode!: string;
    status!: number;
    created_at!: string;
    updated_at!: string;
}

export class Vehicle
{
    id!: number;
    name!: string;
    type!:string
    registration!: string;
    _state!: number;
    status!: number;
    created_at!: string;
    updated_at!: string;
}

export class VehicleAssigned {

  id!: number;
  job_id!: number;
  vehicle_id!: number;
  description!: string;
  assigned_by_id!: number;
  _state!: number;
  created_at!: string;
  updated_at!: string;
}


export class Tool {
  id: number;
  name: string;
  type: string;
  status: number;
  created_at: Date;
  updated_at: Date;
}

export class AttachedEquipment {
  id: number;
  report_item_id: number;
  equipment_id: number;
  status: number;
  created_at: Date;
  updated_at: Date;
  tool: Tool;
}

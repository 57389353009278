<div class="inline-chat-frame" *ngIf="show_inline_chat_panel">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="card custom-card">
                <div class="main-content-app pt-0">
                    <div class="main-content-body main-content-body-chat">
                        <div class="main-chat-header pt-3">
                            <div class="card-header border-bottom-0 pt-0 pl-0 pr-0 d-flex">
                                
                                <div ngbDropdown class="ml-auto" style="float:right;">
                                    <a href="javascript:;" class="option-dots" ngbDropdownToggle aria-haspopup="true"
                                        aria-expanded="false"><i class="fe fe-more-vertical"></i></a>
                                    <div ngbDropdownMenu>
                                    
                                        <a ngbDropdownItem href="javascript:;" (click)="inviteStaff()"><i class="fe fe-user-plus mr-1"></i> Participants </a>
                                        <a ngbDropdownItem href="javascript:;" (click)="sendLog()"><i class="fe fe-mail mr-1"></i>Send Message</a>
                                        <copy-chat [channel]="channel"></copy-chat>
                                        <!-- <a ngbDropdownItem href="javascript:;" (click)="onCopyLog()"><i class="fe fe-mail mr-1"></i>Copy Channel</a> -->
                                        <!-- <a ngbDropdownItem href="javascript:;" ><i class="fe fe-envelope mr-1"></i>Close Chat</a> -->

                                    </div>
                                </div>
                                <div style="margin-right:90px">
                                    <label class="main-content-label mb-1">{{ job_name }}</label> 
                                    <span class="d-block tx-12 text-muted">Job/Event Group Chat</span>
                                </div>
                            </div>
                        </div><!-- main-chat-header -->
                        <div class="main-chat-body" id="ChatBody" style="padding-bottom: 50px;" #scrollMe>
                            <div class="content-inner">
                                
                                <div class="" *ngFor="let message of messages">
                                    
                                    <!-- <label class="main-chat-time"><span>Today</span></label> -->

                                    <div class="media" [class.flex-row-reverse]="message.source == source">
                                        <div class="main-img-user online"><img alt="" src="../../assets/img/users/1.jpg"></div>
                                        <div class="media-body">
                                            
                                            <div class="main-msg-wrapper">
                                                {{ message.message=="image" ? "" : message.message }}
                                                <br/>
                                                <div class="pd-0" *ngIf="message.path">
                                                    <img alt="" class="wd-150 mb-1 mr-1" src="{{ getChatImageUrl(message.path) }}">
                                                </div>
                                                <br />
                                                <small *ngIf="message._source">{{ message._source.name }}</small>
                                            </div>
                                            
                                            <!-- <div class="pd-0" *ngIf="message.path"> -->
                                                <!-- <img alt="" class="wd-150 mb-1 mr-1" src="{{ getChatImageUrl(message.path) }}"> -->
                                                <!-- <img alt="" class="wd-150 mb-1 mr-1" src="../../assets/img/media/4.jpg">
                                                <img alt="" class="wd-150 mb-1" src="../../assets/img/media/5.jpg"> -->
                                            <!-- </div> -->
                                            <div>
                                                <span>{{ setDateTime(message.created_at) }}</span> <a href="javascript:;"><i class="icon ion-android-more-horizontal"></i></a>
                                            </div>
                                            
                                        </div>
                                    </div>

                                </div>
                            
                            </div>
                        </div>
                        <div class="">
                            <span *ngIf="chatImage" class="badge badge-info badge-light m-2">
                                <span>Image</span>
                                <span class="ml-2" (click)="chatImage=''"><i class="fa fa-times"></i></span>
                            </span>
                            <div *ngIf="isTyping" class="typing">Typing...</div>
                        </div>
                        <div class="main-chat-footer">
                            <nav class="nav">
                                <a class="nav-link ml-0" (click)="openImagePicker(imageInput)" href="javascript:;" placement="top" ngbTooltip="Add Photo"><i class="fe fe-image"></i></a>
                                <!-- <a class="nav-link" href="javascript:;" placement="top" ngbTooltip="Attach a File"><i class="fe fe-paperclip"></i></a> -->
                                <!-- <a class="nav-link" href="javascript:;" placement="top" ngbTooltip="Emoji"><i class="far fa-smile"></i></a> -->
                                <!-- <a class="nav-link" href="javascript:;" placement="top" ngbTooltip="Record Voice"><i class="fe fe-mic"></i></a> -->
                            </nav>
                            <input class="form-control" (keyup)="onEnterPressed($event, message)" placeholder="Type your message here..." type="text" #message>
                            
                            <a class="main-msg-send" (click)="onSend(message)" href="javascript:;"><i class="far fa-paper-plane"></i></a>
                        </div>

                        <input type="file" name="file" style="display: none;" id="file" (change)="handleFileInput($event.target.files)" #imageInput>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<chat-invite [identifier]="job_identifier" [owner_id]="job_session_id" [name]="job_name"></chat-invite>
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../shared/services/auth.service';
import { first } from 'rxjs/operators';
import { CookieService } from '../shared/services/cookie.service';
import { UserPayload } from '../shared/models/auth.models';
import { UserService } from 'src/app/shared/services/users/user.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public loginForm: FormGroup;
  public submitted: boolean = false;
  public isloading: boolean = false;

  public newUser: UserPayload = new UserPayload();

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authentication: AuthenticationService,
    private cookieService: CookieService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {

    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    })
  }

  get loginFormControl(){
    return this.loginForm.controls;
  }

  public isSignUpFailed:boolean = false;
  public isSignUpSuccess:boolean = false;

  submit(){

    let $this = this

    this.isSignUpFailed = false;
    this.isSignUpSuccess = false;

    this.newUser.code = this.route.snapshot.paramMap.get('index');

    // let payload = encodeURIComponent(window.btoa(JSON.stringify({staff:true,access:"6789376787654334567",means:"qrcode"})));

    // // console.log("Encodeing ",payload)

      this.userService.send("create", this.newUser, function(result, status){

        if(status){
            $this.isSignUpSuccess = true;
            return;
        }
        $this.isSignUpFailed = true
    })

  }
}
